import React from "react"
import { 
  Layout
} from '../components/'
import "./reset.css"
import "./index.css"

class FourOhFour extends React.Component {
  render() {
    return (
      <Layout>
        <p>404 - Page Not Found</p>
      </Layout>
    )
  }
}

export default FourOhFour